import React, { Component } from "react";
import {
  CardMedia,
  Grid,
  Card,
  Typography,
  Select,
  Menu,
  MenuItem,
  InputBase,
  Paper,
  Button,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import EventBus from "eventing-bus";
import { Redirect } from "react-router-dom";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../managers/api/ApiManager";
import RideLoader from "./RideLoader";
import DialogForm from "../../../custom/DialogForm";
import ShowFavDriver from "./ShowFavDriver";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import { STATIC_MAP } from "../../../../values/Icons";
import { CURRENCY } from "../../../../values/Typography";
import {
  GoogleApiWrapper,
  InfoWindow,
  Map,
  Marker,
  Polyline,
} from "google-maps-react";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import BookingOtpDetails from "./BookingOtpDetails";
import { GOOGLE_API_KEY } from "../../../../constants/AppConstants";
// import BookingOtpDetails from "./BookingOtpDetails";

const google = window.google;

class ConfirmBookingContainer extends Component {
  state = {
    isTrackVisible: false,
    booking_id: "",
    isFavDriverVisible: false,
    isLoaderVisible: false,
    isVisibleEnterCoupon: false,
    coupon_text: "",
    checkoutData: this.props.data,
    payment_option: [],
    showSnackbar: false,
    snackbarVariant: "success",
    snakBarMessage: "",
    payment_option_id: null,
    showSnackbar: false,
    isRedirectVisible: false,
    loading: true,
    findRide: true,
  };

  componentDidMount() {
    console.log("EEEEF" + this.props.data);
  }

  redirectToTrack = () => {
    if (this.state.isTrackVisible) {
      return <Redirect to={"/driver/track_ride"} />;
    }
  };

  getMapSizeImage(image) {
    let image1 = image.split("&size");
    return image1[0] + "&size=400x110";
  }

  executeCancelRideApi = () => {
    this.setState({ findRide: false }, () => {
      clearInterval(this.intervalID);
    });
    ApiManager.callPostApi(
      "Ride Loader",
      "auto cancel ride api",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_AUTO_CANCEL_API,
      {
        booking_id: this.state.booking_id,
      }
    )
      .then((res) => {
        if (res.result === "1") {
          this.props.onBackPress();
        }
      })
      .catch((err) => alert(JSON.stringify(err)));
  };

  componentWillMount() {
    EventBus.on("RIDE_EVENT", (data) => {
      this.setState({
        isLoaderVisible: !this.state.isLoaderVisible,
        isTrackVisible: true,
      });
    });
  }

  handleChange = (event) => {
    this.executeChnagePaymentApi(event.target.value);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.state.checkoutData) {
      this.setState({
        checkoutData: nextProps.data,
        payment_option_id: nextProps.data.SelectedPaymentMethod.id,
      });
    }
  }

  monitorBookingStatus = () => {
    if (this.state.isLoaderVisible && this.state.findRide) {
      ApiManager.callPostApi(
        "BookingStatus",
        "Booking status api",
        ApiEndPoints.APPORIO_TAXI_APIS.BOOKING_STATUS,
        {
          booking_id: String(this.state.booking_id),
        }
      ).then((res) => {
        console.log("DWDWD" + res.data.booking_status);
        if (res.result == 1) {
          if (
            res.data.booking_status == "1002" ||
            res.data.booking_status == 1002
          ) {
            this.setState(
              {
                showSnackbar: true,
                snackbarVariant: "success",
                snakBarMessage: res.message,
              },
              () => {
                this.setState({ isLoaderVisible: false }, () => {
                  clearInterval(this.intervalID);
                  this.booking_details();
                });
              }
            );
          }
        }
      });
    }
  };

  executeConfirmBookingApi = () => {
    if (this.state.payment_option_id) {
      ApiManager.callPostApi(
        "ConfirmBookingContainer",
        "Confirm Booking api",
        ApiEndPoints.APPORIO_TAXI_APIS.BOOKING_CONFIRM,
        {
          checkout: String(this.props.data.id),
        }
      )
        .then((res) => {
          if (res.result === "1") {
            if (this.props.bookingType == 2) {
              this.setState({
                showSnackbar: true,
                snakBarMessage: "Booked SuccessFully",
                booking_id: res.data.id,
              });
            } else {
              this.setState({
                isLoaderVisible: !this.state.isLoaderVisible,
                booking_id: res.data.id,
              });
            }
          } else {
            this.setState({
              showSnackbar: true,
              snackbarVariant: "error",
              snakBarMessage: res.message,
            });
          }
        })
        .catch((err) => alert(JSON.stringify(err)));
    } else {
      this.setState({
        showSnackbar: true,
        snackbarVariant: "error",
        snakBarMessage: "Please select a payment method.",
      });
    }
  };

  booking_details = () => {
    {
      ApiManager.callPostApi(
        "Booking details api",
        "Booking details api",
        ApiEndPoints.APPORIO_TAXI_APIS.BOOKING_DETAILS,
        {
          booking_id: String(this.state.booking_id),
        }
      )
        .then((res) => {
          if (res.result === "1" && res.data.otp_enable == "1") {
            this.setState({
              booking_data: res.data,
            });
            alert("Your otp is : " + res.data.ride_otp);
            // this.setState({
            //   checkoutData: res.data,
            //   payment_option_id: res.data.SelectedPaymentMethod.id
            // });
          } else {
            alert(JSON.stringify(res.message));
          }
        })
        .catch((err) => alert(JSON.stringify(err)));
    }
  };

  executeApplyCouponApi = () => {
    if (this.state.coupon_text !== "") {
      ApiManager.callPostApi(
        "ConfirmBookingContainer",
        "Coupon Apply api",
        ApiEndPoints.APPORIO_TAXI_APIS.APPLY_COUPON,
        {
          checkout_id: String(this.props.data.id),
          promo_code: this.state.coupon_text,
        }
      )
        .then((res) => {
          if (res.result === "1") {
            this.setState({
              checkoutData: res.data,
              payment_option_id: res.data.SelectedPaymentMethod.id,
            });
          } else {
            this.setState({ coupon_text: "" });
            alert(JSON.stringify(res.message));
          }
        })
        .catch((err) => alert(JSON.stringify(err)));
    } else {
      alert("Please  enter coupon name");
    }
  };

  executeChnagePaymentApi = (id) => {
    ApiManager.callPostApi(
      "ConfirmBookingContainer",
      "chnage Payment option api",
      ApiEndPoints.APPORIO_TAXI_APIS.CHANGE_PAYMENT_OPTION,
      {
        checkout: String(this.props.data.id),
        payment_option: String(id),
        card_id: "",
      }
    )
      .then((res) => {
        if (res.result === "1") {
          console.log("%^%^" + JSON.stringify(res));
          this.setState({
            checkoutData: res.data,
            payment_option_id: res.data.SelectedPaymentMethod.id,
          });
        } else {
          this.setState({
            showSnackbar: true,
            snakBarMessage: res.message,
            snackbarVariant: "error",
          });
        }
      })
      .catch((err) => alert(JSON.stringify(err)));
  };
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidMount() {
    ApiManager.callPostApi(
      "ConfirmBookingContainer",
      "Payment option api",
      ApiEndPoints.APPORIO_TAXI_APIS.PAYMENT_OPTIONS,
      {
        checkout_id: this.props.data.id,
      }
    )
      .then((res) => {
        console.log("gegege" + JSON.stringify(res));
        if (res.result === "1") {
          this.setState(
            { payment_option: res.data, payment_option_id: res.data[0].id },
            this.setState({ loading: false })
          );
        }
      })
      .catch((err) => alert(JSON.stringify(err)));
    this.intervalID = setInterval(() => this.monitorBookingStatus(), 8000);
  }

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false,
    });
  };
  resetComponent = () => {
    return <Redirect to="/Home" />;
  };
  render() {
    const { startLocation, dropLocation } = this.props;
    return (
      <div
        style={{
          backgroundColor: "red",
          width: "100%",
          height: "100%",

          justifyContent: "center",
          flexDirection: "column",
          position: "absolute",
        }}
      >
        {this.state.isLoaderVisible ? this.monitorBookingStatus() : null}
        {this.redirectToTrack()}
        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />
        <RideLoader
          onCancelApiExecution={() => this.executeCancelRideApi()}
          onClose={() => this.resetComponent()}
          showLoader={this.state.isLoaderVisible}
        />
        <Grid item style={{ position: "absolute", top: 0 }}>
          <IconButton onClick={() => this.props.onBackPress()}>
            <ArrowBack />
          </IconButton>
        </Grid>

        <Paper style={{ width: "100%", height: "100vh" }}>
          <img
            src={this.getMapSizeImage(this.props.data.map_image)}
            // image="https://maps.googleapis.com/maps/api/staticmap?size=900x500&path=enc%3AohqfIc_jpCkE%7DCx%40mJdDa%5BbD%7BM%7D%40e%40_MgKiQuVOoFlF%7DVnCnBn%40aDlDkN%7DDwEt%40%7DM%7DB_TjBy%7C%40lEgr%40lMa%60BhSi%7C%40%7COmuAxb%40k%7BGh%5E_%7BFjRor%40%7CaAq%7DC~iAomDle%40i%7BA~d%40ktBbp%40%7DqCvoA%7DjHpm%40uuDzH%7Dm%40sAg%7DB%60Bgy%40%7CHkv%40tTsxAtCgl%40aBoeAwKwaAqG%7B%5CeBc_%40p%40aZx%60%40gcGpNg%7CBGmWa%5CgpFyZolF%7BFgcDyPy%7CEoK_%7BAwm%40%7BqFqZaiBoNsqCuNq%7BHk%60%40crG%7B%5DqkBul%40guC%7BJ%7D%5DaNo%7B%40waA%7DmFsLc_%40_V%7Dh%40icAopBcd%40i_A_w%40mlBwbAiiBmv%40ajDozBibKsZ%7DvAkLm%5DysAk%7DCyr%40i%60BqUkp%40mj%40uoBex%40koAk_E_hG%7B%60Ac%7DAwp%40soAyk%40ogAml%40%7Bg%40qKsNeJw%5DeuA%7D%60Fkm%40czBmK%7Bg%40wCed%40b%40_e%40dT%7BgCzx%40csJrc%40ejFtGi%60CnB_pFhCa%60Gw%40%7Du%40wFwaAmP%7BoA%7Dj%40etBsRm_AiGos%40aCyy%40Lic%40tFohA~NeoCvC_%7CAWm~%40gb%40w~DuLex%40mUk_Ae_%40o_Aol%40qmAgv%40_%7DAaf%40qhAkMcl%40mHwn%40iCuq%40Nqi%40pF%7D%7CE~CyiDmFkgAoUedAcb%40ku%40ma%40cl%40mUko%40sLwr%40mg%40awIoA_aApDe~%40dKytAfw%40kyFtCib%40%7DA%7Bj%40kd%40usBcRgx%40uFwb%40%7BCulAjJmbC~CumAuGwlA_%5Du_C_PqyB%7BI%7DiAwKik%40%7DUcr%40ya%40up%40%7DkB%7DoCoQ%7Da%40aMyf%40an%40wjEimBuwKiYybC%7DLuyBoJ%7DhBuMieAwd%40i%7BB%7B~%40g%60D_Si%5Dsi%40%7Bk%40cPeSuH_T%7DNct%40kNcmC_Gyr%40mq%40_~AkmA%7DkCksByrE_N%7Bc%40oAcs%40%60J%7Bi%40t%7DByaHxNqt%40tGgxA%7CJ%7BkGeJ_aDsQi_HmFwuAmI%7BdA_XijByFgv%40%7DAiwBxDocAdM%7BlAtSmcAfUmaAptAmbGh~AcvGbwBc%7DHff%40shB~Isp%40nQu%7DB%60UsuCbBok%40l%40%7DzAhIwbA~OuaAnYwp%40rYwe%40%7CNke%40zc%40%7BhBrOwRdo%40sf%40xNaTb_%40uy%40ta%40k~%40xTap%40hl%40uiCre%40unHlIi~AlFsc%40rEkk%40aAce%40mL%7DlAwPcyB_GohBzDsqAtMqtA~h%40weDtFkd%40Bi%60%40_XwfEdAag%40dEkM%60%40zAqApJef%40%7BP_o%40sYys%40ai%40yf%40_j%40y_%40oi%40mVi%5EmFqSwAiPtDuQbc%40_nAtZyaAlEkc%40r%40eq%40%7CAo%5BrTwcAtVuz%40vQ%7Dd%40%7CPmb%40xT%7B%5CzZyd%40jG%7BRzL%7Dh%40jr%40ov%40rFiImFqPiD%7BJ&key=AIzaSyA8M9gAGweshJ74YNfYBrPf4SjxIoWED8E&zoom=7"
            style={{
              width: "100%",
              backgroundSize: "100%",
            }}
          />
          ,
          <div
            style={{
              justifyContent: "flex-start",
              direction: "column",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Grid container xl justify="space-between">
              <Grid justify="center" item xs={6} style={{ padding: 10 }}>
                <div style={{ justifyContent: "flex-start" }}>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      padding: 5,
                      color: "#40D590",
                    }}
                  >
                    {LanguageManager.Strings.FROM_LABEL}
                  </Typography>
                  <Typography
                    style={{
                      padding: 5,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    {this.state.checkoutData.pickup_location}
                  </Typography>
                </div>
              </Grid>
              <Grid justify="center" item xs={6} style={{ padding: 10 }}>
                <div style={{ justifyContent: "flex-start" }}>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      padding: 5,
                      color: "#F14741",
                    }}
                  >
                    {LanguageManager.Strings.TO_LABEL}
                  </Typography>
                  <Typography
                    style={{ fontSize: 14, padding: 5, fontWeight: 400 }}
                  >
                    {this.state.checkoutData.drop_location}
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Grid
              xl
              justify="flex-end"
              container
              style={{ display: this.props.showFavDriver ? "flex" : "none" }}
            >
              <CardMedia
                onClick={() =>
                  this.setState({
                    isFavDriverVisible: !this.state.isFavDriverVisible,
                  })
                }
                style={{
                  background: "#5AD1EF",
                  color: "white",
                  padding: 10,
                  marginRight: 10,
                  fontSize: 14,
                  borderRadius: 20,
                }}
              >
                {LanguageManager.Strings.FAV_DRIVER_LABEL}
              </CardMedia>
            </Grid>
            <Grid xl container justify="space-between">
              <Grid xs={4} item>
                <Grid
                  alignItems="center"
                  style={{ background: "#F3F3F3", padding: 10, marginTop: 10 }}
                  item
                  xl
                >
                  <div style={{ justifyContent: "flex-start" }}>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        textAlign: "center",
                      }}
                    >
                      {LanguageManager.Strings.ARRIVE_LABEL}
                    </Typography>
                    {this.state.checkoutData.estimate_distance &&
                    this.state.checkoutData.estimate_time ? (
                      <Typography
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        {this.state.checkoutData.estimate_distance +
                          "(" +
                          this.state.checkoutData.estimate_time +
                          ")"}
                      </Typography>
                    ) : (
                      <Typography
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        ---
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid
                  alignItems="center"
                  style={{ padding: 10, marginTop: 10, background: "#F3F3F3" }}
                  item
                  xl
                >
                  <div style={{ justifyContent: "flex-start" }}>
                    <Typography style={{ fontSize: 12, fontWeight: 400 }}>
                      {LanguageManager.Strings.PAYMENT_OPTION_LABEL}
                    </Typography>
                    {this.state.payment_option_id ? (
                      <Select
                        onChange={this.handleChange}
                        value={this.state.payment_option_id}
                        color="black"
                        displayEmpty
                        style={{
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                          width: "70%",
                          fontWeight: "bold",

                          backgroundColor: "#F3F3F3",
                          fontSize: 16,
                        }}
                        name="Select"
                        disableUnderline={true}
                      >
                        {this.state.payment_option.map((val, index) => (
                          <MenuItem
                            style={{ fontSize: 16, background: "white" }}
                            value={val.id}
                          >
                            {val.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
              <Grid
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                item
              >
                <div style={{ justifyContent: "flex-start" }}>
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 80 / 2,
                      border: "2px solid black",
                    }}
                  >
                    <Avatar
                      src={
                        ApiEndPoints.TAXI_IMAGE_URL +
                        this.state.checkoutData.vehicleTypeImage
                      }
                      style={{ width: 60, height: 60 }}
                    />
                  </div>
                  <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                    {this.state.checkoutData.vehicleTypeName}
                  </Typography>
                </div>
              </Grid>
              <Grid xs={4} item>
                <Grid
                  alignItems="center"
                  style={{ background: "#F3F3F3", padding: 10, marginTop: 10 }}
                  item
                  xl
                >
                  <div style={{ justifyContent: "flex-start" }}>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        textAlign: "center",
                      }}
                    >
                      {LanguageManager.Strings.RIDE_ESTIMATE}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 22,
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#40D590",
                      }}
                    >
                      {this.state.checkoutData.estimate_bill}
                    </Typography>
                  </div>
                </Grid>

                <Grid
                  style={{ background: "#F3F3F3", padding: 10, marginTop: 10 }}
                  item
                  xl
                >
                  <div style={{ justifyContent: "flex-start" }}>
                    <Typography
                      style={{ fontSize: 14, fontWeight: 400, padding: 5 }}
                    >
                      {LanguageManager.Strings.APPLY_COUPON_LABEL}
                    </Typography>
                    {this.state.checkoutData.promo_code === "" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <InputBase
                          onChange={(event) =>
                            this.setState({ coupon_text: event.target.value })
                          }
                          value={this.state.coupon_text}
                          placeholder="Enter Coupon"
                          style={{ fontSize: 14 }}
                        />
                        <Button
                          onClick={() => this.executeApplyCouponApi()}
                          style={{
                            backgroundColor: "#5AD1EF",
                            color: "white",
                            margin: 0,
                            padding: 0,
                            fontSize: 14,
                          }}
                        >
                          {LanguageManager.Strings.APPLY_LABEL}
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#5AD1EF",
                          }}
                        >
                          {this.state.checkoutData.promo_code}
                        </Typography>
                        <Button
                          onClick={() =>
                            this.setState({ isVisibleEnterCoupon: true })
                          }
                          style={{
                            backgroundColor: "#5AD1EF",
                            color: "white",
                            margin: 0,
                            padding: 0,
                            fontSize: 12,
                          }}
                        >
                          {LanguageManager.Strings.CHANGE_LABEL}
                        </Button>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/* <Button
            onClick={() => this.executeConfirmBookingApi()}
            style={{
              backgroundColor: "#5AD1EF",
              color: "white",
              width: "50%",
              marginTop: "1%",
              marginBottom: '1%',
              borderRadius: 15
            }}>
            {LanguageManager.Strings.CONFIRM_BOOKING_BUTTON}
          </Button> */}
          <div style={{ margin: "1%", position: "relative" }}>
            <Button
              style={{
                backgroundColor: "#5AD1EF",
                color: this.state.loading ? "#5AD1EF" : "white",
                width: "50%",
                marginTop: "1%",
                marginBottom: "1%",
                borderRadius: 15,
              }}
              disabled={this.state.loading}
              onClick={() => this.executeConfirmBookingApi()}
            >
              {LanguageManager.Strings.CONFIRM_BOOKING_BUTTON}
            </Button>
            {this.state.loading && (
              <CircularProgress
                size={24}
                style={{
                  color: "green",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
          <DialogForm
            visible={this.state.isFavDriverVisible}
            onOutsideTouch={() => {
              this.setState({
                isFavDriverVisible: !this.state.isFavDriverVisible,
              });
            }}
          >
            <ShowFavDriver checkoutId={this.props.data.id} />
          </DialogForm>
          {/* <DialogForm
            visible={this.state.boooking_otp}
            onOutsideTouch={() => {
              this.setState({
                boooking_otp: !this.state.boooking_otp
              });
            }}
          >
            <BookingOtpDetails checkoutId={this.props.data.id} />
          </DialogForm> */}
        </Paper>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(ConfirmBookingContainer);

// const myMap= GoogleApiWrapper({
//   apiKey: (GOOGLE_API_KEY)
// })(ConfirmBookingContainer)

// GoogleMapsContainer.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// export default withStyles(Styles)(myMap);
